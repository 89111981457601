/* The switch - the box around the slider */
.switchWatchlist {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

    /* Hide default HTML checkbox */
    .switchWatchlist input {
        opacity: 0;
        width: 0;
        height: 0;
    }

/* The slider */
.sliderWatchlist {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

    .sliderWatchlist:before {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

input:checked + .sliderWatchlist {
    background-color: #2196F3;
}

input:focus + .sliderWatchlist {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .sliderWatchlist:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.sliderWatchlist.roundWatchlist {
    border-radius: 34px;
}

.sliderWatchlist.roundWatchlist:before {
    border-radius: 50%;
}

.WatchlistNav {
    width: 95%;
    padding: 25px 10px 5px 0px;
    margin-left: 36px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 500;
}