.Screener {
    margin-top: 5px;
    margin-bottom: 110px;
}

.SectorMobile {
    max-width: 100px;
    white-space: normal;
    text-align: left;
    padding-left: 20px;
}

.IndustryMobile {
    max-width: 100px;
    white-space: normal;
    text-align: left;
    padding-left: 20px;
}

.intersection {
    z-index: 100;
    left: 0 !important;
    position: sticky !important;
}

.ScreenerSubBlock {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 0px 10px 10px 10px;
    font-size: 14px;
    width: 25%;
}

.predefinedScreenerOptions {
    display: grid;
    grid-template-columns: auto auto auto auto;
    font-size: 14px;
    text-align: center;
}

a:link {
    color: blue;
}

a:visited {
    color: blue;
}

a:active {
    color: blue;
}

.dragableSectionAttached {
    padding: 5px 10px;
    border: 1px solid #DDD;
    border-radius: 5px;
}

.lowerAttached {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    min-height: 50px;
    text-align: center;
}

.upperAttached {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    text-align: center;
}

.PeersTwo {
    padding-left: 20px;
}
