.login {
    min-height: 240px;
    text-align: left;
    margin-left: 600px;
    margin-top: 100px;
    margin-bottom: 100px;
    width: 320px;
    padding: 20px;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
}
#googleLogin{
    text-align: center;
    padding-left: 50px;
}

.ErrorPlaceholder {
    min-height: 20px;
    color: red;
    padding-top: 5px;
}
.login {
    line-height: 36px;
    font-size: 26px;
    text-align: center;

}

.login input {
    font-size: 20px;
    width: 95%;
    margin-top: 20px;
    border-radius: 10px;
    border: 2px solid #F5F5F5;
    padding: 5px;
}

.login select {
    font-size: 20px;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
    padding: 5px;
    border: 2px solid #F5F5F5;
}

.ErrorPlaceholder {
    font-size: 16px;
    text-align: left;
    padding-left: 20px;
}
.LoginButtonMain {
    width: 100%;
    line-height: 24px;
    font-size: 20px;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    margin-top: 20px;
    padding: 5px;
    cursor: pointer;
}
.loginHeading{
    text-align:center;
    font-weight: 800;
}
.ForgetPassword {
    font-size: 18px;
    padding-top: 20px;
    text-decoration: none;
    color: black;
}
.ForgetPassword:hover {
    color: blue;
}

.RegisterLink:hover {
    color: blue;
}
.RegisterLink {
font-size: 18px;
padding-top: 20px;
text-decoration: none;
color: black;
}