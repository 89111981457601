.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 1000;
}

    .modal.enter-done {
        opacity: 1;
        pointer-events: visible;
    }

    .modal.exit {
        opacity: 0;
    }

.modal-content {
    width: 90%;
    height: 95%;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    transform: translateY(-200px);
    text-align: justify;
    text-justify: inter-word;
}

.modal.enter-done .modal-content {
    transform: translateY(0);
}

.modal.exit .modal-content {
    transform: translateY(-200px);
}

.modal-header {
    padding: 10px;
    text-align: left;
}

.modal-footer {
    padding: 10px;
    text-align: right;
}

.modal-title {
    margin: 0;
}

.modal-body {
    width: 90%;
    height: 85%;
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    overflow-y: auto;
}
