.HomeContentSection {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    width:900px;
}

.ContentBlock {
    color: #777;
    font-size: 14px;
    font-weight: 500;
    width: 150px;
    height: 30px;
    padding: 20px 5px;
    border: 1px solid #dadce0;
    border-radius: 10px;
}



#RightHomeContent {
    padding-top: 20px 25px;
    line-height: 45px;
    width: 50%;
    text-align: left;
}

.RoundHeader {
    padding: 10px 15px;
    border-radius: 5px;
    max-width: 35px;
    font-size: 24px;
    font-weight: 800;
    background-color: #0070f3;
    box-shadow: 0 4px 14px 0 rgb(0 118 255 / 39%);
    transition: background 0.2s ease,color 0.2s ease,box-shadow 0.2s ease;
    color: white;
}

#LeftHomeContent {
    padding-top: 20px;
    text-align: right;
    line-height: 45px;
    width: 50%;
}

#BuffetImage {
    width: 650px;
    height: 400px;
}

.intendedContent {
    font-size: 15px;
    font-weight: 400;
}

#nkSpan {
    font-size: 45px;
    font-weight: 600;
    color: #36454f;
}

#textContent {
    text-align: left;
    font-size: 22px;
    font-weight: 800;
    float: right;
}

#textContentTwo {
    text-align: left;
    font-size: 22px;
    font-weight: 800;
    float: left;
}

.HomeContentMain {
    padding-top: 50px;
}

#MainTextBottom {
    font-size: 16px;
    text-align: center;
    padding-left: 200px;
    padding-right: 200px;
}

#MarkerText {
    padding-top: 40px;
    font-size: 36px;
    letter-spacing: 5px;
    color: #ff9900;
}

.ContentSection {
    transition: all 0.3s ease;
    padding: 30px 0px;
}


.contentSectionUserRatioImage {
    height: 350px;
    width: 100%;
}

.HomeSectionThreeImageOne {
    position: absolute;
    z-index: 1;
    width: 90%;
    top: 0px;
    left: 0px;
}

.HomeSectionThreeImageTwo {
    position: absolute;
    z-index: 2;
    width: 90%;
    top: 100px;
    left: 70px;
}

.ContentSectionThree {
    min-height: 850px;
}



.ContentSectionFourText {
    padding: 30px 40px;
}

.FourTextAlign {
    text-align: left;
}



.ContentSectionFiveText {
    padding: 30px 40px;
}

.HomeSectionFiveImageContainer {
    padding: 10px 5px;
}

.HomeSectionFiveImage {
    height: 300px;
    width: 100%;
}

.ContentSectionContainerSix {
    display: flex;
    justify-content: space-evenly;
}

.ContentSectionLeftSix {
    width: 70%;
}

.ContentSectionRightSix {
    width: 30%;
}

.HomeSectionSixImage {
    height: 310px;
    width: 100%;
}

.ContentSectionTextSix {
    padding: 30px 40px;
}

.sixHeader {
    padding-left: 30px;
}
