
.Peers {
    margin-left: 20px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 110px;
    
}
.tabledataPeer {
    text-align: center;
    color: #000;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

thead th {
    top: 0;
    position: sticky;
    z-index: 3;
}


.PeersMain {
    min-height: 400px;
    margin-left: 36px;
    margin-right: 40px;
    margin-top: 10px;
    padding-top: 10px;
}
.PeersTable {
    
    padding: 0px 10px 2px 0px;
    font-size: 14px;
    width: 100%;
    overflow: auto;
}

.ScreenerTable {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    padding-left: 10px;
    font-size: 14px;
    width: 100%;
    line-height: 1.5;
    padding-top: 0px !important;
}

.PeersOption {
    display: flex;
    justify-content: space-between;
    max-width: 700px;
    padding-bottom: 20px;
}
.peerSection {
    display: flex;
    justify-content: space-between;
}
.peerHeader{
    font-weight: 600;
}
.leftPeer {
    font-weight: 400;
    text-align: left;
    padding-left: 10px;
    position: sticky;
    left: 0;
    background-color: white;
    border-bottom: 1px solid #F5F5F5;
}
.leftHeader{
    text-align: left;
    
}
.columnHeading{
    font-weight: 600;
}
.columnHeadingScreener {
    font-weight: 600;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
    background-color: white;
    white-space: nowrap;
    border-bottom: 1px solid #F5F5F5;
}
.columnHeadingScreenerLeft {
    font-weight: 600;
    text-align: left;
    padding-right: 20px;
    background-color: white;
}
.PeerTabledataLeft {
    text-align: left;
    padding-left: 10px;
    max-width: 100%;
    white-space: nowrap;

}

.PeerTabledataRight {
    text-align: right;
    padding-right: 10px;
    max-width: 100%;
    white-space: nowrap;
}

.PeerTabledataCenter {
    text-align: center;
    padding-right: 10px;
    max-width: 100%;
    white-space: nowrap;
}
.PeerColumnHeading {
    font-weight: 600;
    text-align: left;
    padding-left: 10px;
    background-color: white;
}
.StockLink{
    color: blue;
    cursor: pointer;
}

.PeerSectionTable {
    overflow: auto;
    width: 100%;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    background-color: white;
}
