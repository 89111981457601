.HomeHeaderMobile {
    position: fixed;
    background-color: #36454f;
    width: 100%;
    height: 50px;
    z-index: 200;
    display: flex;
    justify-content:space-between;
}
.logoAnchorMobile {
    color: white;
    text-decoration: none;
}
.LogoTextMobile {
    padding-top: 15px;
    font-size: 24px;
    color: white;
    font-weight: 600;
    padding-left: 5px;
    
}
.leftHeaderMobile {
    left: 20px;
    display: flex;
    justify-content: start;
}
#HomeHeaderLogoMobile{
    height: 25px;
    width: 35px;
    padding-top: 13px;
}
.leftOptionIconMobile {
    padding-top: 17px;
    padding-right: 20px;
    padding-left: 10px;
}
.searchContainerMobile{
    padding-top: 14px;
    padding-right: 20px;
}
.SearchAreaMobile {
    position: fixed;
    background-color: #4a5760;
    width: 100%;
    height: 50px;
    z-index: 200;
    margin-top: 50px;
}
.NavAreaMobile {
    position: fixed;
    background-color: #4a5760;
    width: 80%;
    min-height: 300px;
    z-index: 400;
    margin-top: 50px;
    border-top: 2px solid #ffd700;
}
.userInfoMobile {
    height: 60px;
    width: 100%;
    background-color: #868f95;
    display: flex;
    justify-content: start;
}
.NavAreaMobile{
    text-align: left;
}
.NavMemberMobile {
    text-align: left;
    padding: 10px;
    margin-bottom: 5px;
    color: #fff;
    background-color: #5e6a72;
}

.NavMemberMobileLogin {
    color: #fff;
}

.NavOptionAnchorMobile {
    text-decoration: none;
    color: #fff;
}
.userLogoutMobile{
    text-align: center;
    font-size: 18px;
    color: white;
    font-weight: 800;
    padding-top: 10px;
    padding-bottom: 20px;
}

.userContainerMobile {
    border: 2px solid #ffd700;
    border-radius: 28px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.userInfoContainerMobile {
    padding-left: 20px;
    padding-top: 25px;
    font-size: 16px;
    color: white;
}
