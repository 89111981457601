.HomeHeader {
    padding-top: 10px;
    display: grid;
    grid-template-columns: auto auto auto;
    position: fixed;
    background-color: hsl(245, 40%, 98%);
    z-index: 1000;
    width: 100vw
}
#MobileOptions {
    display: none;
}

.LogoText {
    padding-top: 7px;
    font-weight: 600;
}
.logoAnchor:visited {
    color: black;
    font-weight: 400;
}
.logoAnchor:active {
    color: black;
    font-weight: 400;
}
.logoAnchor:hover {
    color: black;
    font-weight: 400;
}

.logoAnchor {
    color: black;
    font-weight: 400;
}

#HomeHeaderLogo {
    height: 23px;
    width: 40px;
    padding-right: 10px;
    padding-top: 5.5px;
}
#logo {
    margin-top: 0px;
    font-size: 23px;
    text-align: left;
    display: inline-flex;
    justify-content: left;
    cursor: pointer;
}
#rightoptions {
    display: inline-flex;
    justify-content: right;
    padding-top: 7px;
    line-height: .8;
}
.user {
    padding-top: 8px;
    font-size: 18px;
    width: 220px;
}


#headerOption {
    display: grid;
    grid-template-columns: auto auto;
    padding-top: 7px;
}
.userInfo {
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
}

.UserName {
    padding-left: 10px;
    padding-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: .8;
}
.userInfoName {
    font-size: 14px;
}
