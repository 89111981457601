@media only screen and (max-width: 1060px) {
    .Pricing {
        padding-top: 80px;
        margin-left: 10px !important;
        margin-right: 10px !important;
        margin-bottom: 110px;
    }
    .userInfoName {
        font-size: 10px !important;
    }
    .LockedContainer {
        display: block !important;
    }
    .LockedContainerChild {
        margin-bottom: 20px;
        min-width: 200px !important;
    }
    .HomeContentSection {
        padding-top: 30px;
        display: grid;
        grid-template-columns: auto auto !important;
        grid-column-gap: 10px !important;
        grid-row-gap: 5px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: 60px !important;
    }
    .ContentBlock {
        min-width: 50px !important;
        padding: 10px 5px !important;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 15px;
        transition: all 0.3s ease;
        
    }
    .contentText {
        font-size: 12px !important;
    }
    .ContentSection {
        padding: 40px 10px !important;
    }
    .ContentSectionText {
        font-size: 12px !important;
    }
    .FrameWorkHeading {
        font-size: 12px !important;
    }
    .ContentSectionLeftOne {
        width: 100% !important;
        padding-left: 50px !important;
    }

    .ContentSectionRightOne {
        width: 100% !important;
        padding-top: 60px !important;
    }
    .ContentSectionContainerOne {
        display: block !important;
    }

    .ContentSectionRightTwo {
        width: 100% !important;
        position: relative !important;
        top: 0px !important;
        left: 0px !important;
        z-index: 1;
    }

    .ContentSectionLeftTwo {
        width: 100% !important;
        position: relative !important;
        top: 10px !important;
    }
    .ContentSectionLeftThree {
        width: 100% !important;
    }
    .ContentSectionContainerThree {
        display: block !important;
    }
    .ContentSectionRightThree {
        width: 100% !important;
    }
    .contentSectionUserRatioImage {
        width: 100% !important;
    }

    .HomeSectionThreeImageOne {
        position: relative !important;
        width: 100% !important;
        top: 0px;
        left: 0px;
    }

    .HomeSectionThreeImageTwo {
        position: relative !important;
        width: 100% !important;
        top: 0px !important;
        left: 0px !important;
    }
    .ContentSectionThree {
        min-height: 150px !important;
    }
    .ContentSectionContainerFour {
        display: block !important;
        position: relative;
        min-height: 350px;
    }

    .ContentSectionLeftFour {
        width: 100% !important;
        padding-top: 10px !important;
        top: 200px;
        position: absolute;
    }

    .ContentSectionRightFour {
        width: 100% !important;
        top: 0px;
        position: absolute;
    }
    .ContentSectionFourText {
        padding: 10px !important;
    }
    .ContentSectionContainerFive {
        display: block !important;
    }
    .ContentSectionLeftFive {
        width: 100% !important;
    }

    .ContentSectionRightFive {
        width: 100% !important;
    }

    .HomeHeader {
        padding: 10px !important;
        width: 95vw !important;
    }
    .ContentSectionFiveText {
        padding: 10px !important;
    }
    .HomeSectionFiveImageContainer {
        padding: 10px !important;
    }
    .HomeSectionFiveImage {
        height: 200px !important;
    }
    .ContentSectionContainerSix {
        display: block !important;
        position: relative;
        min-height: 450px;
    }
    .ContentSectionTextSix {
        padding: 10px !important;
    }
    .sixHeader {
        padding-left: 10px !important;
    }

    .ContentSectionLeftSix {
        width: 100% !important;
        top: 220px;
        position: absolute;
    }

    .ContentSectionRightSix {
        width: 100% !important;
        top: 0px;
        position: absolute;
    }
    .HomeSectionSixImage {
        height: 200px !important;
    }
    .ScoreRow {
        display: block !important;
    }
    .ScoreHalfColumn {
        padding: 10px !important;
        width: 100% !important;
        margin-bottom: 10px;
    }


    .ChecklistGridContainer {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }

    .ChecklistGridContainerStart {
        margin-right: 4px !important;
    }

    .ChecklistGridContainerEnd {
        margin-left: 4px !important;
    }
    .StratergyContainer {
        display: block !important;
    }

    .StratergyContainerChild {
        width: 100% !important;
        margin-bottom: 10px;
    }
    .StratergyContainerChildHeader {
        font-size: 12px !important;
    }

    .StratergyContainerChildContent {
        font-size: 12px !important;
    }


    .HomeContent {
        display: none;
    }

    #logo {
        font-size: 16px !important;
    }

    .LogoText {
        padding-top: 4px !important;
    }

    #HomeHeaderLogo {
        height: 13px !important;
        width: 20px !important;
    }

    #headerOption {
        display: none !important;
    }

    #nkSpan {
        font-size: 20px !important;
    }

    .HomeContentMain {
        padding-top: 80px !important;
        padding-bottom: 20px !important;
    }

    .intendedContent {
        text-indent: 10px !important;
        font-size: 10px !important;
    }

    #MainTextBottom {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    #MarkerText {
        font-size: 26px !important;
    }

    .Footer {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .FooterRow {
        display: block !important;
    }

    .FooterColumn {
        width: 80vw !important;
    }

    .companyLinks {
        width: 80vw !important;
    }

    .companyLinksContent {
        display: flex;
        justify-content: space-evenly;
    }

    .FooterColumnOneTwo {
        width: 80vw !important;
    }

    .FooterColumnTwo {
        width: 80vw !important;
    }

    .FooterColumnThree {
        width: 80vw !important;
        text-align: center !important;
    }

    .RightElement {
        width: 80vw !important;
        clear: both !important;
        padding-left: 10px;
    }

    #rightoptions {
        padding-top: 0px !important;
    }

    #HomeSection {
        min-height: 55vh;
        max-width: 100vw;
    }

    #MobileOptions {
        display: block !important;
        padding-top: 5px;
        padding-left: 10px;
    }

    .HomeContentMobile {
        display: block !important;
    }

    .dropdown-container {
        text-align: center !important;
    }

    .dropdown-input {
        justify-content: center !important;
    }

    .Searchbox {
        text-align: center;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin: 10px;
    }

    .commonContainer {
        padding: 70px 10px !important;
    }

    .FeedbackBracket {
        width: 20% !important;
        padding-top: 30px !important;
        padding-left: 15px !important;
    }


    .CoffeeContainer {
        display: block !important;
    }

    .CoffeeFirst {
        width: 100% !important;
        display: block !important;
    }

    .CoffeeFirstText {
        padding-left: 10px !important;
        padding-top: 20px !important;
        font-family: bradley hand, cursive;
        font-size: 22px;
        line-height: 2;
    }

    .CoffeeSecond {
        width: 100% !important;
    }

    .CompanyNav {
        width: 95vw !important;
        overflow-x: auto !important;
        margin-left: 10px !important;
        font-size: 8px !important;
        gap: 10px;
    }

    .CompanyMain {
        display: block !important;
    }

    #compInfo {
        display: block !important;
    }

    #CompanyMainCompanyName {
        font-size: 18px !important;
    }

    #CompanyMainLeft {
        padding-left: 10px !important;
    }

    #CompanyMainCompanyEodPrice {
        font-size: 14px !important;
    }

    #CompanyMainCompanyEodChange {
        font-size: 12px !important;
    }

    #exchangeInfo {
        display: block !important;
        width: 50vw !important;
    }

    #CompanyMainRight {
        display: block;
        padding-right: 5px !important;
        padding-left: 10px;
        margin-top: 5px !important;
    }

    .finSum {
        display: block !important;
        margin: 0px 10px 10px 10px !important;
    }

    .finColumn {
        width: auto !important;
        margin-right: 0px !important;
        margin-left: 0px !important;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 13px !important;
    }
    h2 {
        font-size: 14px !important;
    }

    .detailsHeading {
        font-size: 13px !important;
    }

    .details {
        font-size: 12px !important;
    }

    .CompanyMenu {
        padding: 5px 5px !important;
    }

    .CompanyNavContainer {
        width: 96vw !important;
        overflow-x: scroll;
    }

    .FinancialNav {
        width: 95vw !important;
        font-size: 9px !important;
        gap: 10px;
    }

    .ValuationNav {
        width: 95vw !important;
        font-size: 9px !important;
        gap: 10px;
    }

    .Financials {
        margin-left: 10px !important;
        margin-right: 10px !important;
        margin-top: 0px !important;
    }

    .FinancialMenu {
        padding: 5px !important;
        font-size: 7px !important;
    }

    .finance {
        width: 95vw !important;
    }

    .optionsFinData {
        display: block !important;
    }

    .leftAlign {
        padding-top: 5px !important;
        font-size: 14px !important;
        color: black !important;
        font-weight: 600 !important;
        text-align: left;
    }

    .optionButton {
        padding: 5px !important;
        font-size: 10px !important;
    }

    .rightAlign {
        font-size: 12px !important;
    }

    .PeersTable {
        font-size: 9px !important;
    }

    .small {
        font-size: 9px !important;
    }

    element.style {
        color: rgb(0, 0, 0);
    }

    .leftPeer {
        font-weight: 400;
        text-align: left;
        padding-left: 10px;
        position: sticky;
        left: 0;
        background-color: white;
    }

    .SubHeader {
        border-top: 1px solid #F5F5F5;
    }

    .stikeyHeader {
        left: 0;
        position: sticky;
    }

    .leftBold {
        min-width: 100px !important;
    }

    .tabledata {
        min-width: 50px !important;
    }

    #mainContentSection {
        padding-top: 40px !important;
    }

    .AboutHeader {
        font-size: 10px !important;
        padding-top: 9px !important;
    }

    .AboutValue {
        font-size: 9px !important;
    }

    .RatiosMain {
        margin-left: 10px !important;
        margin-right: 10px !important;
        margin-top: 10px;
        padding-top: 10px;
        width: 95vw;
    }

    .finData {
        font-size: 13px !important;
    }

    .ratioHeaderOne {
        font-size: 10px !important;
    }

    .AddWatchlist {
        padding-left: 10px !important;
    }

    .existingWatchlist {
        display: block !important;
    }

    .modal-bodyMR {
        width: 95vw !important;
        height: 70vh !important;
        padding: 10px 10px !important;
        overflow-x: auto;
    }

    .modal-body {
        height: 70vh !important;
        padding: 10px 10px !important;
    }


    .LeftManageRatioOption {
        min-width: 1000px;
    }

    .RightManageRatioOption {
        padding-top: 5px;
        justify-content: left !important;
    }

    .ManageRatioContent {
        min-width: 1000px;
    }

    .selectedRatios {
        min-width: 1000px;
    }

    .compProfile {
        display: block !important;
    }

    .corpGrev {
        font-size: 11px !important;
        padding-left: 20px;
    }

    .OddDivSProfile {
        padding-left: 0px !important;
        overflow: auto;
        width: 85vw;
    }

    .tabledataEmployeeLeft {
        min-width: 35px !important;
    }

    .EmployeeTable {
        width: 85vw !important;
    }

    .tabledataEmployee {
        min-width: 35px !important;
    }

    .GrowthMain {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .growthCompanyName {
        padding-left: 0px !important;
    }

    .GrowthWrapper {
        display: block !important;
    }

    .Growth {
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-bottom: 10px !important;
        overflow: auto !important;
        width: 95vw !important;
    }

    .leftBoldGrowthHead {
        font-size: 11px !important;
    }

    .Valuations {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .ValuationRow {
        display: block !important;
    }

    .ValuationColumnThreeQuarters {
        width: 85vw !important;
    }

    .ValuationColumnOneQuarters {
        margin-top: 10px;
        width: 85vw !important;
    }

    .finRight {
        min-width: 35px !important;
    }

    .chartContainer {
        padding: 10px 0px !important;
    }

    .chartHeader {
        display: block !important;
    }

    .chartMenuItem {
        font-size: 9px !important;
    }

    .ValuationchartTopic {
        justify-content: start !important;
    }

    .chartMenu {
        min-width: 20px !important;
        padding-top: 5px;
    }

    .valuationChartChart {
        height: 250px !important;
    }

    .chartSummaryDetailsTD {
        font-size: 9px !important;
    }

    .halfQuarter {
        width: 95vw !important;
        font-size: 9px !important;
    }

    .halfQuarterOne {
        width: 95vw !important;
        font-size: 9px !important;
    }

    .halfQuarterTwo {
        width: 95vw !important;
        font-size: 9px !important;
    }

    .middle {
        padding-top: 5px;
        padding-left: 5px !important;
        width: 150px;
    }

    .paddedUserInput {
        line-height: 1 !important;
    }

    .userInput {
        line-height: 1 !important;
    }

    .customInput {
        font-size: 9px !important;
    }

    .paddedInput {
        padding: 3px 3px !important;
    }

    .ValuationRowInner {
        margin-top: 10px;
    }

    .FullQuarter {
        width: 95vw !important;
        overflow: auto !important;
    }

    .CorporateActionsMain {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .DividendRowHighlightsSub {
        display: block !important;
        font-size: 11px !important;
    }

    .DividendHighlights {
        width: 95vw !important;
        margin-bottom: 5px;
    }

    .HeighlightHeader {
        font-size: 10px !important;
    }

    .HeighlightContent {
        font-size: 9px !important;
    }

    .DividendRow {
        display: block !important;
    }

    .CAOneQuarter {
        width: 95vw !important;
        max-height: 300px;
    }

    .CAThreeQuarter {
        width: 95vw !important;
        max-height: 300px;
        margin-top: 5px;
    }

    .PeersMain {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .Peers {
        margin-left: 0px !important;
        padding-right: 0px !important;
    }

    .peerHeader {
        font-size: 9px;
    }

    .peerContent {
        font-size: 8px;
    }

    .bookContainer {
        width: 10vw;
        height: 12vw;
    }

    .BookGrid {
        grid-template-columns: auto !important;
        padding-left: 80px !important;
    }

    .Disclaimer {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .Screener {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .optionButtonPrimary {
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
    .ScreenerHeader {
        padding-left: 0px !important;
    }
    .ScreenerBlock {
        display: block !important;
    }
    .ScreenerSubBlock {
        width: 88vw !important;
        margin-bottom: 5px;
        font-size: 13px !important;
    }
    .ScreenerContainer {
        font-size: 10px !important;
    }
    .PeersTwo {
        padding-left: 0px !important;
    }
    .predefinedScreenerOptions {
        grid-template-columns: auto !important;
        font-size: 13px !important;
    }
    .LeftScreenerOption {
        min-width: 100vw !important;
    }
    .MiddleScreenerOption {
        min-width: 100vw !important;
    }
    .RightScreenerOption {
        min-width: 100vw !important;
    }
    .selectedFilters {
        padding: 10px 5px !important;
        grid-template-columns: auto !important;
        font-size: 11px !important;
    }
    .ScreenerResultPrompt {
        font-size: 13px !important;
    }
    .ScreenerResultText {
        padding-top: 0px !important;
        font-size: 13px;
    }
    .SuperHeader {
        font-weight: 600;
        font-size: 13px !important;
    }
    .ExceptionButton {
        margin-top: 0px !important;
    }
}

.SuperHeader {
    font-weight: 600;
    font-size: 18px;
}
    

.ScreenerBlock {
    display: flex;
    justify-content: start;
    gap: 20px;
    padding-top: 10px;
}


.ScreenerHeader {
    text-align: left;
    padding-left: 20px;
}


.leftBoldGrowthHead {
    font-weight: 600 !important;
    font-size: 18px;
}

.OddDivSProfile {
    border-radius: 10px;
    padding-left: 20px;
}
.MobileOptionList {
    align-items: center;
    background-color: white;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
}

.commonContainer {
    padding: 110px 300px;
    text-align: left;
}

.FeedbackBracket{
    width: 20%;
    padding-top: 30px;
    padding-left: 45px;
}
.CoffeeContainer{
    display: flex;
    justify-content: space-between;
}
.CoffeeFirst{
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.CoffeeFirstText {
    padding-left: 120px;
    padding-top: 160px;
    font-family: bradley hand, cursive;
    font-size: 22px;
    line-height: 2;
}

.CoffeeSecond{
    width: 40%;
}
.finColumn {
    max-height: 400px;
    overflow-y: auto;
}
.ratioHeaderOne {
    font-weight: 600 !important;
    font-size: 18px;
}
.compProfile{
    display: flex;
    justify-content:space-between;
}

.valuationChartChart {
    width: 100%;
    height: 450px;
}

valuationChart {
    width: 100%;
    height: 340px;
}

.BookGrid{
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding-left: 40px;
}
.ScreenerResultPrompt {
    font-size: 20px;
}
.ScreenerResultText{
    padding-top: 4px;
    padding-left: 10px;
    font-weight: 400;
}
.ExceptionButton{
    margin-top: 5px;
}
.FrameWorkHeading {
    font-size: 18px;
    color: #0062B1;
    font-weight: 600;
}
.StratergyContainer {
    display: flex;
    justify-content: space-between;
}
.StratergyContainerChild{
    width: 32%;
}
.StratergyContainerChildHeader {
    text-align: center;
    background-color: #36454f;
    color: white;
    font-size: 18px;
    font-weight: 600;
}
.StratergyContainerChildContent {
    font-size: 14px;
    border: 1px solid #666;
    padding: 5px;
    height: 93%;
}

