.faq {
    padding-top: 110px;
    padding-left: 120px;
    padding-right: 150px;
    text-align: left;
    padding-bottom: 100px;
}

p {
    text-align: justify;
    font-size: 16px;
    line-height: 1.6;
}

intendTwo{
    padding-left: 20px;
}
