.ProfitLoss {
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 100px;
    overflow: auto;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
}
#ProfitLossGraph {
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 100px;
}
