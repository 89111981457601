.BalanceSheet {
    margin-bottom: 110px;
    overflow: auto;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
}
#BalanceSheetGraph {
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 60px;
}
.leftBold {
    font-weight: 400;
    text-align: left;
    padding-left: 10px;
    min-width: 250px;
}
.finHead{
    text-align:right !important;
}

.stikeyHeader {
    left: 0;
    position: sticky;
    background-color: white;
}
.headingWithPopup {
    display: flex;
    justify-content: space-between;
}

.ViewSelected {
    -webkit-appearance: none;
    padding: 3px 40px 3px 12px;
    border: 1px solid #E8EAED;
    border-radius: 5px;
    background: white;
    box-shadow: 0 1px 3px -2px #9098A9;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: all 150ms ease 
}

.leftIntend {
    font-weight: 400;
    text-align: left;
    padding-left: 20px;
}
#ExpandMenu {
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}
#ExpandMenu:hover {
    transform: translateY(-3px);
}
.tabledata {
    padding-top: 5px;
    padding-bottom: 5px;
}
.SubHeader {
    border-bottom: 1px solid #F5F5F5;
}
.chartIcon {
    width: 20px;
    height: 15px;
}

.ExpandImage {
    width: 20px;
    height: 20px;
}