.AboutUs-container {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    padding: 20px;
}
#QuoteImage {
    float: left;
    padding-right: 20px;
    padding-top: 10px;
}
blue {
    color: #055C9D;
    line-height: 1.5;
}
intend {
    padding-left: 70px;
    line-height: 1.5;
}
.free-sign-up{
    line-height: 1.8;
}