.CompanyNav {
    width: 100%;
    padding: 25px 10px 5px 0px;
    margin-left: 36px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 500;
    position: fixed;
    background-color: hsl(245, 40%, 98%);
    z-index: 110;
}

.CompanyMenu {
    cursor: pointer;
    background-color: hsl(245, 40%, 98%);
    padding: 5px 20px;
    border-radius: 5px;
}
.CompanyMenu:hover {
    background-color: hsl(245, 40%, 90%);
}
#mainContentSection{
    padding-top: 45px;
}
#mainDetails {
    background-color: white;
}
.Company {
    background-color: white;
}

.WatchlistInput {
    position: relative;
    cursor: text;
    font-size: 14px;
    line-height: 20px;
    padding: 0 16px;
    height: 35px;
    background-color: #fff;
    border: 1px solid #d6d6e7;
    border-radius: 3px;
    color: rgb(35, 38, 59);
    box-shadow: inset 0 1px 4px 0 rgb(119 122 175 / 30%);
    overflow: hidden;
    transition: all 100ms ease-in-out;
                
}

.WatchlistInput:focus {
    border-color: #3c4fe0;
    box-shadow: 0 1px 0 0 rgb(35 38 59 / 5%);
} 