.finDataMobile {
    width: 100%;
    border-spacing: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.5;
    font-size: 12px;
    margin-bottom: 20px;
}
.columnHeadingMobile{
    white-space:nowrap;
    font-weight: 600;
}
.leftBoldMobile {
    white-space: nowrap;
    font-weight: 600;
    text-align: left;
    padding-left: 10px;
}