.FinancialNav {
    width: 95%;
    padding: 5px 0px 5px 0px;
    display: flex;
    justify-content: left;
    font-size: 12px;
    position: fixed;
    z-index: 110;
    background-color: rgb(248, 248, 252);
    border-bottom: 2px solid #F5F5F5;
}
.Financials{
    margin-left: 36px;
    margin-right: 40px;
    margin-top: 10px;
}

.FinancialMenu {
    cursor: pointer;
    background-color: hsl(245, 40%, 98%);
    padding: 5px 20px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
}

.FinancialMenu:hover {
    background-color: hsl(245, 40%, 90%);
    }
#finNum {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
}

.finance {
    width: 100%;
}

.Checklist {
    width: 0%;
    font-weight: 600;
    
}
.ChecklistWrapper {
    border: 2px solid #F5F5F5;
    padding: 20px 5px 25px 5px;
    border-radius: 10px;
    min-height: 460px;
}
.checklist-option {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #F5F5F5;
}
.checklist-basic {
    width: 50%;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
}
.checklist-pro {
    width: 50%;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
}
.checklist-basic:hover {
    transform: translateY(-3px);
}
.checklist-pro:hover {
    transform: translateY(-3px);
}
.ChecklistOptionButton {
    padding-top: 2px;
    width: 100px;
    display: flex;
    justify-content: space-between;
    border: 2px solid #F5F5F5;
    cursor: pointer;
}
.checklist-header {
    padding-top: 8px;
}
