.container .rectangle {
    transition-duration: .3s;
    transition-property: filter, opacity;
    opacity: 1;
    filter: saturate(100%);
}

.container:hover .rectangle {
    opacity: .4;
    filter: saturate(50%);
}

.container .rectangle:hover {
    opacity: 1;
    filter: saturate(100%);
}

.inintialRect {
    opacity: 1;
    filter: saturate(100%);
}

