.AssistantNav{
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.AssistantNav:hover{
    background-color: hsl(245, 40%, 90%);
}

.react-responsive-modal-modal{
    max-width: 1300px !important;
}

button {
    display: inline-block;
    outline: 0;
    cursor: pointer;
    border: none;
    padding: 0 56px;
    border-radius: 7px;
    font-weight: 400;
    font-size: 16px;
    background-color: #0070f3;
    box-shadow: 0 4px 14px 0 rgb(0 118 255 / 39%);
    transition: background 0.2s ease,color 0.2s ease,box-shadow 0.2s ease;
    color: white;
    z-index: 5;
    margin-right: 5px;
}

button:hover {
    background: rgba(0,118,255,0.9);
    box-shadow: 0 6px 20px rgb(0 118 255 / 23%);
    transition-duration: .1s;
}

@media (min-width: 768px) {
    button {
        padding: .4rem .8rem;
    }
}
