.CommodityElement {
    padding: 5px 20px;
    background-color: hsl(218, 34%, 96%);
    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    margin: 5px 10px;
    border-radius: 10px;
}
th {
    padding-top: 12px;
}
