.HeaderAnchor {
    text-decoration: none;
    color: black;
    padding-left: 40px;
}
.FixedBannerMember {
    color: #606f7b;
    cursor: pointer;
}
.FixedBannerMember:hover {
    color: blue;
    cursor: pointer;
}
.FixedBannerMember:active {
    font-weight: 600;
    cursor: pointer;
}



