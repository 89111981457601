.customInputMobile {
    height: 100%;
    width: 70px;
    border-color: none;
    font-size: 12px;
}
.userInputMobile {
    display: flex;
    justify-content: space-between;
    line-height: 1.5;
    width: 100px;
}
.ValuationOddDivMobile {
    display: grid;
    grid-template-columns: auto auto;
    padding: 5px 10px 5px 0px;
}
.ValuationEvenDivMobile {
    display: grid;
    grid-template-columns: auto auto;
    padding: 5px 10px 5px 0px;
}

.rightRoundedMobile {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    min-width: 20px; 
    font-weight: 600;
    color: black;
}
.leftRoundedMobile {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: 40px;
    font-weight: 600;
    color: black;
}

.customInputRangeMobile {
    height: 100%;
    width: 30px;
    border-color: none;
}