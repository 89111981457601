.FinancialNavMobile {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    font-size: 12px;
    font-weight: 600;
    position: fixed;
    background-color: white;
    z-index: 200;
    padding-top: 5px;
    overflow-x: auto;
}
