
.Growth {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 20px;
    overflow: auto;
    width: 49%;
    padding: 5px 10px;
    background-color: white;
}


.GrowthMain {
    margin-left: 18px;
    margin-right: 30px;
    padding-top: 10px;
}
.GrowthWrapper {
    display: flex;
    justify-content: space-between;
}

.leftBoldGrowth {
    font-weight: 400;
    text-align: left;
    padding-left: 10px;
}
.growthCompanyName{
    padding-left: 20px;
}


