.CompanyNameMobile{
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 5px;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
}
.ExchangeContainerMobile {
    padding-bottom: 20px;
}
#exchangeInfoMobile {
    display: flex;
    justify-content: start;
    column-gap: 50px;
    font-size: 12px;
}
.companyInfoMobile {
    border-bottom: 2px solid #f5f5f5;
}
#EODDetailsMobile{
    display: flex;
    justify-content: start;
    column-gap: 10px;
    padding-left: 5px;
}
#EodPriceMobile{
    font-size: 18px;
    font-weight: 600;
}
#EodChangeMobile {
    font-size: 14px;
    font-weight: 600;
}
#EodDateMobile {
    font-size: 10px;
    padding-top: 10px;
    color: grey;
}
#WatchlistButton{
    width: 30%;
    font-size: 18px;
    padding-left: 5px;
}
.optionButtonMobile {
    padding: 5px 10px;
    cursor: pointer;
    border: solid 1px #007FFF;
    border-radius: 5px;
    color: white;
    background-color: #007FFF;
    font-weight: 600;
    font-size: 13px;
}
.optionButtonWatchlistMobile {
    padding: 5px 10px;
    cursor: pointer;
    border: solid 1px #007FFF;
    border-radius: 5px;
    color: white;
    background-color: #007FFF;
    font-weight: 600;
    font-size: 10px;
}

