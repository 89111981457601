.LeftManageScreenerOption {
    display: flex;
    justify-content: left;
    padding-top: 10px;
    font-size: 12px;
}

.LeftManageScreenerOptionButton {
    cursor: pointer;
    padding: 5px 10px;
    border: solid 1px #000;
}

.LeftManageScreenerOptionButtonStart {
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    cursor: pointer;
    padding: 5px 10px;
    border: solid 1px #000;
}

.LeftManageScreenerOptionButtonEnd {
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    cursor: pointer;
    padding: 5px 10px;
    border: solid 1px #000;
}

.ScreenerOption {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
}
.LeftScreenerOption {
    width: 33%;
}
.RightScreenerOption {
    width: 33%;
}
.MiddleScreenerOption {
    width: 33%;
}
.ScreenerOptionRow {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}
.VariableOptionCustom{
    min-width: 100px;
}

.VariableOption {
    min-width: 20%;
    text-align: left;
    padding-top: 8px;
    font-weight: 600;
     
}
.VariableOptionPreDefined {
    width: 60%;
    text-align: left;
    padding-right: 20px;
}
.VariableOptionCustom {
    min-width: 50%;
    text-align: left;
    margin-top: 5px;
}
.css-2613qy-menu{
    z-index: 10000;
}
.ManageScreener{
    font-size: 13px;
}
.css-1s2u09g-control {
    min-height: 20px !important;
    border: none !important;
}
.css-6j8wv5-Input {
    padding-top: 2px !important;
    padding-bottom: 3px !important;
}
.css-tlfecz-indicatorContainer {
    padding: 2px !important;
}

.css-1gtu0rj-indicatorContainer {
    padding: 2px !important;
}

.css-1pahdxg-control {
    min-height: 20px !important;
}

.customInputMini {
    border: 1px solid #0096FF;
    border-radius: 5px;
    height: 50px;
    line-height: normal;
    color: #282828;
    display: block;
    width: 100%;
    box-sizing: border-box;
    user-select: all;
    font-size: 16px;
    padding: 0 6px;
    padding-left: 12px;
}
