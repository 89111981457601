
.Ratios {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: auto;
    background-color: white;
    padding: 5px 10px;
}

#RatiosGraph {
    padding: 20px;
}

.RatiosMain {
    margin-left: 36px;
    margin-right: 40px;
    margin-top: 10px;
    padding-top: 10px;
    width: 95%;
}
.ratioWidth{
    min-width: 250px;
}
.popupChart{
    z-index: 1000;
}