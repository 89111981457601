

blue {
    color: #055C9D;
    line-height: 1.5;
}

intend {
    padding-left: 70px;
    line-height: 1.5;
}
