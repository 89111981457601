.modalMR {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 1000;
}

    .modalMR.enter-done {
        opacity: 1;
        pointer-events: visible;
    }

    .modalMR.exit {
        opacity: 0;
    }

.modal-contentMR {
    width: 99%;
    height: 99%;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    transform: translateY(-200px);
    text-align: justify;
    text-justify: inter-word;
}

.modalMR.enter-done .modal-contentMR {
    transform: translateY(0);
}

.modalMR.exit .modal-contentMR {
    transform: translateY(-200px);
}

.modal-headerMR {
    width: 90%;
    padding: 10px;
    text-align: left;
}

.modal-footerMR {
    padding: 10px;
    text-align: right;
}

.modal-titleMR {
    margin: 0;
}

.modal-bodyMR {
    width: 95%;
    height: 85%;
    padding: 10px 40px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    overflow-y: auto;
}
