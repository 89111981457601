.finData {
    width: 100%;
    border-spacing: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.5;
    font-size: 14px;
}
    .finData tr:hover {
        /*background: #ffff99;*/
        background: #E7EAED;
    }
.small{
    font-size: 14px;
}
/*  Define the background color for all the ODD table columns  
.finData tr td:nth-child(odd) {
    background: hsl(245, 40%, 98%);
}

.finData tr td:nth-child(even) {
    background: #fff;
}
.finData tr td:nth-child(1) {
    background: #fff;
}*/



.leftHeading {
    font-weight: 400;
    text-align: left;
    padding-left:10px;
    
}
.columnHeading {
    font-weight: 400;
    text-align: right;
    padding-right: 10px;
    background-color: white;
}
.tabledata {
    text-align: right;
    color: #000;
}
.QuaterlyResults {
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 100px;
    overflow: auto;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
}


.finButton:hover {
    color: blue;
    cursor:pointer;
}
.paddedSpan {
    padding-left: 5px;
    color: blue;
}
.tabledata {
    min-width: 80px;
    padding-right: 10px;
}
.sort {
    cursor: pointer;
}
.optionsFinData {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}
.optionButton {
    padding: 8px 10px;
    cursor: pointer;
    border: solid 1px #007FFF;
    border-radius: 5px;
    color: white;
    background-color: #007FFF;
    font-weight: 600;
    font-size: 14px;
    margin: auto;
}




.optionButton:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
    transform: translateY(-1px);
}
.rightAlign {
    padding-top: 5px;
    display: flex;
    justify-content: right;
    grid-column-gap: 30px;

}
.leftAlign {
    padding-top: 15px;
    font-size: 22px;
    color: black;
    font-weight: 400;
}

.HorizontalAnalysis {
    display: flex;
    overflow: auto;
    flex-direction: row;
}

.inlineDivs {
    display: inline-block;
}

tbody {
    display: table-row-group;
}

.OddTable td {
    background-color: #F5F5F5;
    border-color: #F5F5F5;
}

.OddTable {
    border-spacing: 2px;
    background-color: #F5F5F5;
    padding-top: 20px;
    margin-bottom: 20px;
}

.EvenTable {
    padding-top: 20px;
    margin-bottom: 20px;
}

.ChartSection {
    display: flex;
    justify-content: space-between;
}
.charts {
    width: 48%;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
}
#QuaterlyResultsGraph {
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 100px;
}