.App {
    text-align: center;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;*/
    font-family: GT America, Helvetica Neue, Helvetica, Arial, sans-serif;
    background-color: hsl(245, 40%, 98%);
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#HomeSection {
    padding-top: 40px;
    min-height: 600px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
