.ValuationChart {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    width: 100%;
    text-align: left;
    background-color: white;
    
}

.ValuationchartTopic {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    min-width: 150px;
    background-color: rgb(248, 248, 252);
}
.chartHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
}
.chartMenu {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    min-width: 650px;
}
.chartContainer{
    padding: 10px 20px;
}
.chartMenuItem{
    cursor: pointer;
    padding: 5px 15px;
    font-weight: 400;
    font-size: 14px;
}
.chartSummaryDetails{
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
}

.chartSummaryDetails, th, td {
    padding: 5px;
}

.chartSummaryDetailsTD {
    width: 12.5%;
    text-align: center;
}
.chartSummaryDetailsTDData {
    background-color: #f8f8fc;
    width: 12.5%;
    text-align: center;
    border-radius: 10px;
}
