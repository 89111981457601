
.Holdings {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    margin-left: 20px;
    margin-bottom: 100px;
    overflow:auto;
}

#HoldingsGraph {
    padding: 20px;
}

.HoldingsMain {
    padding-top: 10px;
    padding-left: 10px;
    width: 100%;
    padding-right: 80px;
    max-width: 1400px;
}
