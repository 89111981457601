.Footer {
    padding-left: 36px;
    padding-right: 120px;
    border-top: 2px solid #F5F5F5;
    padding-bottom: 20px;
    background-color: #000;
}
.FooterRow {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    color: #fff;
}
.footerLinkElement:hover {
    color: #FFD700;
}

.Rights {
    text-align: left;
    padding-top: 17px;
}

.FooterColumnTwo {
    width: 40%;
}
.socialAnchor{
    padding-right: 15px;
    padding-left:5px;
}

.FooterColumn {
    width: 12%;
}
.FooterColumnOneTwo {
    width: 18%;
}
.FooterColumnThree {
    width: 30%;
    color: #fff;
    text-align: left;
}
.FooterColumnfour {
    width: 70%;
}
.RightElement {
    float: right;
    line-height: 1.5;
    padding-top: 15px;
    color: #999;
    width: 70%;
}

.SocialIcon {
    width: 40px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
}
.footerBottom {
    padding-bottom: 50px;
    padding-top: 20px;
    font-weight: 400;
    color: #fff;
    background-color: #000;
}

.FooterContent {
    width: 100%;
    padding-top: 20px;
   
}
.footerElement{
    min-width: 350px;
}
.whatsappSection {
    display: flex;
    justify-content: space-between;
}
#whatsapp{
    width: 200px;
    height: 70px;
}
#whatsappQR {
    padding-top:25px;
    width: 70px;
    height: 70px;
    padding-right:80px;
}
.WhatsappText {
    font-size: 18px;
    font-weight: 600;
    color: hsl(0, 0%, 50%);
}
.socialSection {
    padding-left: 40px;
    display: flex;
    justify-content: space-between;
}
.SocialText {
    font-size: 18px;
    font-weight: 600;
    color: hsl(0, 0%, 50%);
}
#youtubeLogo{
    width: 120px;
    height: 25px;
}
#facebookLogo {
    padding-top:10px;
    width: 120px;
    height: 20px;
}
#linkedinLogo {
    width: 120px;
    height: 45px;
}
.SocialLinks {
    font-size: 18px;
    font-weight: 600;
    color: hsl(0, 0%, 50%);
    line-height:1.8;
    padding-right:30px;
}
.SocialLinks a {
    text-decoration: none;
    color: hsl(0, 0%, 50%);
}
.SocialLinks a:hover {
    color: #FFD700;
}
#youtubeContainer{
    padding-top: 8px;
}
#fbContainer{
    padding-left: 20px;
}
#liContainer {
    padding-left: 10px;
}
.companyLinks {
    font-size: 18px;
    font-weight: 600;
    color: hsl(0, 0%, 50%);
    padding-left: 20px;
    padding-right: 20px;
}
.SocialLinksHeader{
    padding-top: 10px;
    padding-left: 60px;
}
.companyLinksHeader {
    padding-top: 10px;
    padding-left: 120px;
}
.companyLinksContent {
    font-size: 18px;
    font-weight: 600;
    color: hsl(0, 0%, 50%);
    line-height: 1.8;
    padding-right: 20px;
    text-align: left;
}
.companyLinksContent a {
    text-decoration: none;
    color: hsl(0, 0%, 50%);
}
.LinkRoute {
    text-decoration: none;
    color: hsl(0, 0%, 50%);
}

.LinkRoute:hover {
    color: #FFD700;
}

.companyLinksContent a:hover {
    color: #FFD700;
}
.terms {
    padding-top: 10px;
    padding-left: 120px;
    line-height: 1.8;
    text-align: left;
}
.footerLinkElement {
    cursor: pointer;
    padding: 10px 10px;
    border-radius: 25px;
    font-weight: 400;
    color: #fff;
    font-size: 14px;
}
.SocialElementHeading{
    color: #fff;
    padding: 15px;
}
.userInfoTwo {
    font-size: 14px;
    color: #888;
}
li {
    line-height: 1.5;
}
