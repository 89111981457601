.Valuations {
    margin-left: 36px;
    margin-right: 40px;
    margin-top: 10px;
}
.relativeValuation td {
    border-bottom: 2px solid #F5F5F5;
}
.ValuationNav {
    width: 94%;
    padding: 5px 10px 10px 0px;
    display: flex;
    justify-content: left;
    font-size: 14px;
    border-bottom: 2px solid #F5F5F5;
    position: fixed;
    z-index: 1;
    background-color: rgb(248, 248, 252);

}
.ValuationRow {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.ValuationRowInner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
}
.ValuationColumnThreeQuarters {
    width: 65%;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    padding: 5px 20px;
    margin-right: 10px;
    background-color: white;
}
.ValuationColumnOneQuarters {
    width: 27%;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    padding: 5px 20px;
    background-color: white;
}

.finBold {
    font-weight: 600;
    text-align: left;
    color: #000;
}
.finRightBold {
    font-weight: 600;
    color: #000;
}
.finLeft {
    font-weight: 400;
    text-align: left;
    color: #000;
}
.finRight {
    text-align: right;
    padding-right: 10px;
    min-width: 120px;
}
#rightWarning {
    padding-top: 30px;
    text-align: left;

}
.FullQuarter {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 110px;
}
.halfQuarter {
    width: 48%;
}
.halfQuarterOne {
    width: 42%;
}
.halfQuarterTwo {
    width: 54%;
}

#companyNameValuation {
    padding-top: 40px;
    text-align: left;
}
