.RightManageRatioOptionMobile {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    padding-top: 10px;
    font-size: 12px;
    width: 100%;
}

.RightManageRatioOptionButtonStart {
    padding: 5px 10px;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border: solid 1px #000;
}

.RightManageRatioOptionButtonEnd {
    padding: 5px 10px;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    border: solid 1px #000;
}

.RightManageRatioOptionButton {
    padding: 5px 10px;
    border: solid 1px #000;
}

.LeftManageRatioOptionMobile {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    padding-top: 10px;
    font-size: 12px;
    width: 100%;
}

.LeftManageRatioOptionButton {
    cursor: pointer;
    padding: 5px 20px;
    border: solid 1px #000;
}

.LeftManageRatioOptionButtonStart {
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    cursor: pointer;
    padding: 5px 10px;
    border: solid 1px #000;
}

.LeftManageRatioOptionButtonEnd {
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    cursor: pointer;
    padding: 5px 10px;
    border: solid 1px #000;
}

.ManageRatioContentMobile {
    margin-top: 10px;
    padding: 20px;
    display: grid;
    grid-template-columns: auto;
    font-size: 13px;
    border: 1px solid #DDD;
    border-radius: 5px;
    max-height: 200px;
    overflow: auto;
}

.MROptions {
    padding: 5px 20px;
    display: flex;
    justify-content: left;
}

.selectedRatiosMobile {
    margin-top: 10px;
    padding: 20px;
    display: grid;
    grid-template-columns: auto;
    font-size: 13px;
    border: 1px solid #DDD;
    border-radius: 5px;
    max-height: 100px;
    overflow: auto;
}

.dragableSection {
    padding: 5px 10px;
    border: 1px solid #DDD;
    border-radius: 15px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    display: inline-block;
    max-height: 20px;
    line-height: 1;
}

.selectedFilters {
    margin-top: 10px;
    padding: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    font-size: 13px;
}


.optionButtonMobile {
    padding: 5px 10px;
    cursor: pointer;
    border: solid 1px #007FFF;
    border-radius: 5px;
    color: white;
    background-color: #007FFF;
    font-weight: 600;
    font-size: 13px;
}