.ValuationOddDivEPS {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 15px 0px;
}

.ValuationEvenDivEPS {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 15px 0px;
}

.topMarginEPS {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
