.chartMenuItemMobile {
    cursor: pointer;
    padding: 5px;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}
.ValuationchartTopicMobile {
    font-weight: 600;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    min-width: 150px;
}
.chartMenuMobile{
    margin-top: 5px;
    display: grid;
    grid-template-columns: auto auto auto;
}
.chartSummaryDetailsTDMobile {
    width: 12.5%;
    text-align: center;
}
.chartSummaryDetailsTDDataMobile {
    background-color: #f8f8fc;
    width: 12.5%;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
}