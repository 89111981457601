.ValuationOddDivS {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 15px 0px;
    
}
.ValuationEvenDivS {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 15px 0px;
    background-color: #F5F5F5;
}
.ValuationOddDiv {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 0px;
}

.ValuationEvenDiv {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 0px;
}
.userInput {
    display: flex;
    justify-content: space-between;
    line-height: 1.5;
    width: 300px;
    
}
.paddedUserOutput{
    padding-top: 4px;
}
.paddedInput {
    height: 100%;
    text-align: center;
    padding: 3px 15px;
}

.grey {
    background-color: hsl(245, 40%, 98%);
}
.customInput {
    height: 100%;
    width: 110px;
    border-color: none;
    font-size: 15px;

}
.customInputRange {
    height: 100%;
    width: 110px;
    border-color: none;
}
input[type=number] {
    border: 1px solid #b8d1f3;
}

input[type=number]:focus {
        border: 2px solid #b8d1f3;
}


.middle {
    padding-top: 5px;
}
.leftRounded {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: 40px;
    font-weight: 600;
    color: black;
}
.rightRounded {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    min-width: 40px;
    font-weight: 600;
    color: black;
}
.valuationResult td {
    border-bottom: 1px solid #F5F5F5;
    border-collapse: collapse;
}
.bold {
    font-weight: 700;
}
.rightIntend {
    text-align: right;
    padding-right: 10px;
}



/* improve visual readability for IE8 and below */
.valuationResult tr {
    background: #b8d1f3;
}
/*  Define the background color for all the ODD table columns  */
.valuationResult tr td:nth-child(odd) {
    background: #fff;
}
/*  Define the background color for all the EVEN table columns  */
.valuationResult tr td:nth-child(even) {
    background: #F5F5F5;
}

.resultBorder {
    color: #367ddd;
}

.optionButtonVal {
    padding: 10px;
    cursor: pointer;
    border: solid 1px #367ddd;
    border-radius: 5px;
    color: #367ddd;
    font-weight: 500;
    width: 45%;
}

.optionButtonVal:hover {
    border: solid 1px blue;
    color: blue
}
.leftHeading {
    width: 100%;
    text-align: left;
    font-weight: 700;
    padding-left: 20px;
    margin: 0px;
}
.valCharts {
    width: 100%;
    margin-top: 10px;
}

.ValuationRowInnerI {
    width: 100%;
    padding-top: 10px;
}

.headerChart {
    text-align: left;
    color: #367ddd;
    font-size: 16px;
}
.borderEnclosed {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    padding: 10px 0px 10px 0px;

}
.topMarginVal {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}
.userButton{
    padding-top: 10px;
}




