.LockedContainer {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    column-gap: 40px;
}
.LockedContainerChild {
    padding: 10px 30px 40px 30px;
    min-width: 400px
}