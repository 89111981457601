.modalMRMobile {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 1000;
    width: 100%;
}

    .modalMRMobile.enter-done {
        opacity: 1;
        pointer-events: visible;
    }

    .modalMRMobile.exit {
        opacity: 0;
    }

.modal-contentMRMobile {
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    transform: translateY(-200px);
    text-align: justify;
    text-justify: inter-word;
}

.modalMRMobile.enter-done .modal-contentMRMobile {
    transform: translateY(0);
}

.modalMRMobile.exit .modal-contentMRMobile {
    transform: translateY(-200px);
}

.modal-headerMRMobile {
    width: 90%;
    padding: 10px;
    text-align: left;
    height: 3%;
}

.modal-footerMRMobile {
    padding: 10px;
    text-align: right;
    height: 10%;
}

.modal-titleMRMobile {
    margin: 0;
}

.modal-contentMRMobile {
    padding: 10px 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.modal-bodyMRMobile {
    
    height: 70%;
    overflow-y: auto;
}
