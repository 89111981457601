.EmployeeTable {
    border: 2px solid #f5f5f5;
    border-radius: 10px;
    font-size: 15px;
    padding: 10px;
    width: 100%;
}
.leftEmployee {
    font-weight: 600;
    padding-left: 10px;
}
.columnHeadingEmployee {
    font-weight: 600;
    padding-right: 10px;
    text-align: left;
}
.columnHeadingEmployeeCenter {
    font-weight: 600;
    padding-right: 10px;
    text-align: center;
}
.tabledataEmployee {
    min-width: 80px;
    padding-right: 10px;
    color: #000;
    text-align: right;
    border-top: 1px solid #f5f5f5;
    padding-top: 10px;
    min-width: 120px;
    text-align: center;
}
.tabledataEmployeeLeft {
    min-width: 80px;
    padding-right: 10px;
    color: #000;
    text-align: right;
    border-top: 1px solid #f5f5f5;
    padding-top: 10px;
    min-width: 120px;
    text-align: left;
}
.AboutHeader{
    font-weight: 600;
}