.ChartInput{
    width: 250px;
    margin: 10px;
}
.ChartInputRow {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    margin-right: 80px;
}
.ChartLeft{
    width: 25%;
}
.ChartRight{
    width: 70%;
}