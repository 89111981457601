.CorporateActionsMain {
    margin-left: 36px;
    margin-right: 40px;
}
.CorporateActions {  
    margin-bottom: 110px;
    padding-top: 10px;
    position:relative;
}

.TableHeader {
    float: left;
    padding-top: 30px;
    padding-left: 10px;
    font-weight: 600;
    border-top: 1px solid #F5F5F5;
    width: 100%;
    text-align: left;
}
.leftAligned {
    text-align: left;
    padding-left: 10px;
}
.rightAligned {
    text-align: right;
    padding-right: 20px;
}
.CANav {
    width: 90%;
    padding: 5px 0px 10px 0px;
    margin-left: 36px;
    display: flex;
    justify-content: left;
    font-size: 14px;
}
.DividendHighlights {
    width: 18%;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    height: 55px;
    background-color: white;  
}
.DividendHighlightsBlank {
    width: 18%;
    min-height: 60px;
    padding-top: 10px;
}
.DividendRow {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    max-height: 500px;
}
.DividendRowHighlights {

    padding: 10px 0px;
    max-height: 500px;
    width: 100%;
}
.DividendRowHighlightsSub {
    display: flex;
    justify-content: space-between;
}
.DividendRowNew {
    display: flex;
    justify-content: space-between;
    padding: 30px 10px;
}
.HeighlightHeader {
    padding-top: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 600;
}
.HeighlightContent {
    padding-top: 5px;
    color: #000;
    font-size: 14px;
}
.CAOneQuarter {
    width: 23%;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    overflow: auto;
    padding-top: 10px;
}
.CAThreeQuarter {
    width: 73%;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    padding-top: 10px;
}
.CAFullQuarter {
    width: 100%;
    padding-top: 10px;
}
.CAChartContainer {
    padding: 20px;
}
.CorporateActionsNav {
    position: fixed;
    background-color: white;
    z-index: 1;
    width: 90%;
    border-bottom: 2px solid #F5F5F5;
    margin-left: 36px;
}

