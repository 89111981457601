.RightManageRatioOption {
    display: flex;
    justify-content: right;
    cursor: pointer;
    font-size: 12px;
}

.removableSection{
    padding: 5px;
}

.removableSection:hover {
    background-color: #bbb;
    border-radius: 25px;
}

.RightManageRatioOptionButtonStart {
    padding: 5px 10px;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border: solid 1px #000;
}
.RightManageRatioOptionButtonEnd {
    padding: 5px 10px;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    border: solid 1px #000;
}
.RightManageRatioOptionButton {
    padding: 5px 10px;
    border: solid 1px #000;
}
.LeftManageRatioOption {
    display: flex;
    justify-content: left;
    padding-top: 10px;
    font-size: 12px;
}
.LeftManageRatioOptionButton {
    cursor: pointer;
    padding: 5px 10px;
    border: solid 1px #000;
}
.LeftManageRatioOptionButtonStart {
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    cursor: pointer;
    padding: 5px 10px;
    border: solid 1px #000;
}
.LeftManageRatioOptionButtonEnd {
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    cursor: pointer;
    padding: 5px 10px;
    border: solid 1px #000;
}
.ManageRatioContent {
    margin-top: 10px;
    padding: 20px;
    display: grid;
    grid-template-columns: auto auto auto;
    font-size: 13px;
    border: 1px solid #DDD;
    border-radius: 25px;
}
.MROptions {
    padding: 5px 20px;
    display: flex;
    justify-content: left;
}
.selectedRatios {
    margin-top: 10px;
    padding: 20px;
    display: grid;
    grid-template-columns: auto auto auto;
    font-size: 13px;
    border: 1px solid #DDD;
    border-radius: 25px;
}
.dragableSection {
    padding: 5px 10px;
    border: 1px solid #DDD;
    border-radius: 15px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    display: inline-block;
    justify-content: space-between;
    max-height: 20px;
    background-color: hsl(218, 34%, 96%);
}

.selectedFilters {
    margin-top: 10px;
    padding: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    font-size: 13px;

}