.dropdown-container {
    text-align: left;
    border: 0px solid #ccc;
    position: relative;
    border-radius: 5px;
}


.dropdown-input {
    display: flex;
    justify-content: space-between;
    user-select: none;
}

.dropdown-menu {
    position: absolute;
    transform: translate(10px, 5px);
    min-width: 100px;
    white-space: nowrap;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;
    max-height: 150px;
    background-color: #fff;
    z-index: 99;
}

.dropdown-item {
    padding: 15px;
    cursor: pointer;
    font-size: 16px;
}

.dropdown-item:hover {
    color: blue;
}

.dropdown-item.selected {
    background-color: #0d6efd;
    color: #fff;
}

.dropdown-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.dropdown-tag-item {
    background-color: #ddd;
    padding: 2px 4px;
    border-radius: 2px;
    display: flex;
    align-items: center;
}

.dropdown-tag-close {
    display: flex;
    align-items: center;
}

.search-box {
    padding: 5px;
    background-color: #eee;
}

    .search-box input {
        width: 100%;
        box-sizing: border-box;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
