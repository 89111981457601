.CompanySummary {
    width: 100%;
    margin-right: 100px;
    
}
.frappe-chart{
    width: auto !important;
}
.AboutValue a {
    text-decoration: underline;
    color: rgb(0, 0, 238);
}
.AboutValue a:link {
    color: rgb(0, 0, 238);
}
.AboutHeader{
    padding-top: 5px;
}

.AboutValue a:visited {
    color: rgb(0, 0, 238);
}

#subMenuBar {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    font-size: 18px;
}
.Reports{
    padding-bottom: 50px;
}
.OddDivS {
    border-radius: 10px;
    padding-left: 20px;
}
.EvenDivS {
    background-color: hsl(245, 40%, 98%);
    border-radius: 10px;
    padding-left: 20px;
}
.DetailsValue {
    color: #000;
    font-weight: 500;
    line-height:20px;
}

.AboutValue {
    color: #000;
    font-weight: 400;
    line-height: 30px;
}

.DivColumn {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    width: 20%;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
    text-align: left;
}
.summary {
    line-height: 30px;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    width: 30%;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
    text-align: left;
}
.DivColumnNormal {
    width: 20%;
    margin-right: 20px;
    text-align: left;
    padding-left: 10px;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
}
.score {
    width: 200px;
    font-weight: 800;
    padding-right: 20px;
}
.Table {
    display: flex;
    justify-content: space-between;
}
.ScoreDetails {
    width: 100%;
    color: hsl(0, 0%, 50%);
    font-weight: 600;
}
.scoreHeader {
    text-align: center;
    font-weight: 600;
    color: hsl(0, 0%, 50%);
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 20px;
}
.chart {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    width: 40%;
    padding-left: 10px;
    margin-right: 20px;
    text-align: left;
}
.secondRow {
    margin-top: 20px;
    padding-bottom: 10px;
}
.About {
    overflow: auto;
}
.DivRow {
    padding-left: 10px;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
}
.CompanySummaryLeft {
    margin: 20px 100px 20px 40px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}
.finSummary {
    width: 100%;
}
h3 {
    font-size: 16px;
    font-weight: 600;
    margin-block-start: .5em !important;
    margin-block-end: .5em !important;
}

.finSum {
    margin: 0px 40px 20px 40px;
    display: flex;
    justify-content: space-between;
}
.finSumMenu {
    margin: 20px 100px 20px 40px;
}
.finColumn {
 
    text-align: left;
    padding: 15px 20px;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    width: 50%;
    background-color: white;
}
.finColumnFull {
    margin-right: 20px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    width: 98%;
}
.finColumnContainer {
    display: flex;
    justify-content: space-between;
}
.finColumnData{
    width: 48%;
}
.CompanySummaryRight {
    display: flex;
    justify-content: space-between;
    margin: 20px 110px 20px 35px;
    font-size: 14px;
    width: 50%;
}
.rowData {
    text-align: left;
    padding: 10px;
    border: 0px;
    border-spacing: 0px;
}
.heading {
    padding: 15px 10px 15px 30px;
    border: 0px;
    font-weight: 500;
    color: hsl(0, 0%, 50%);
    
}
.value {
    padding: 15px 30px 15px 10px;
    border: 0px;
    margin-right: 10px;
    font-weight: 500;
    border-right: 25px solid white;
}


#CompanySummaryRightTop {
    padding: 10px;
    border: 2px solid #32CD30;
    border-radius: 10px;
    margin-right: 10px;
    text-align: left;
    cursor:pointer;
}

#CompanySummaryRightBottom {
    margin-top: 10px;
    padding: 10px;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    margin-right: 10px;
    max-height: 300px;
    overflow: hidden;
    text-align: left;
}
.summaryFont {
    display: flex;
    justify-content: space-between;
    padding: 6px 0px;
}
.detailsHeading{
    font-size: 14px;
}
.details {
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    max-height: 210px;
    font-size: 14px;
}
h1 h2 h3 {
    color: hsl(0, 0%, 50%);
}

.card {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    min-height: 120px;
    padding: 10px;
    cursor: pointer;
    width: 200px;
}
.cardAnnouncement {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}
.cardAnchor {
    width: 200px;
    max-height: 300px;
    color: hsl(0, 0%, 50%);
}
.cardAnchorAnnouncement {
    width: 100%;
    max-height: 300px;
    color: hsl(0, 0%, 50%);
}

.cardImageData {
    width: 50px;
    height: 50px;
}
.cardImageDataDocument {
    width: 45px;
    height: 60px;
}
.cardImage {
    padding-top: 20px;
}
.finColumn a {
    text-decoration: none;
    color: inherit;
}
.cardContent {
    min-width: 60%;
    text-align: left;
    padding-top: 20px;
    padding-left: 10px;
}
.cardContentAnnouncement {
    min-width: 100%;
    max-width: 100%;
    text-align: left;
}
.cardHeaderAnnounContent {
    font-size: 18px;
    overflow-wrap: break-word;
}
.cardHeaderContent {
    font-size: 18px;
}
.cardSourceContent {
    font-size: 14px;
}
.finAnnouncement {
    display: flex;
    justify-content: space-between;
}
.finOption {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding-top: 10px;
}

.ButtonAnnouncement {
    padding: 10px;
    cursor: pointer;
}
.ButtonAnnouncement:hover {
    color: rgb(65, 105, 225);
}

.QuarterWidth {
    width: 25%;
}
.NoDecor {
    text-decoration: none;
    color:black;
}
.fixedHeight {
    max-height: 400px;
    overflow-y: auto;
}
.MainRow {
    display: flex;
    justify-content: left;
}
.SubRow {
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
}

.AboutValue {
    color: black;
}

.AboutValue a {
    text-decoration: none;
}
.finAnnualReport {
    text-align: left;
}
.finCreditRating {
    text-align: left;
}
.subMenu {
    cursor: pointer;
}
.gridview {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}
.gridviewAnnouncement {
    display: grid;
    grid-template-columns: auto;
}
.CompanySummaryMain{
    padding-bottom: 110px;
}
.AboutHeaderTop {
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
}