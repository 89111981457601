.ScoreMain {
    padding-top: 10px;
    padding-left: 10px;
    margin-right: 25px;
    margin-top: 10px;
    font-size: 14px;
}

.ScoreMainPeer {
    padding-top: 10px;
    padding-left: 20px;
    margin-right: 15px;
    margin-top: 10px;
    font-size: 14px;
    margin-bottom: 110px;
}
.Score {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 40px;
    margin-top: 20px;
    overflow: auto;
    padding-top: 10px;
    
    padding-bottom: 10px;
    min-height: 300px;
}

.PeersTable td {
    border-bottom: 2px solid #F5F5F5;
}



.ScoreSubsection {
    padding-left: 20px;
    padding-right: 10px;
}
.ScoreRow {
    display: flex;
    justify-content: space-between;
}
.ScoreHalfColumn {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    padding: 10px 40px;
    width: 43%;
    text-align: left;
    background-color: white;
}
.ChecklistGridContainer {
    padding: 10px 20px;
    border: blue solid 1px;
    border-radius: 5px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
}
.ChecklistGridContainerStart {
    padding: 10px 20px;
    border: blue solid 1px;
    border-radius: 5px;
    text-align: center;
    margin-right: 10px;
    margin-top: 10px;
}
.ChecklistGridContainerEnd {
    padding: 10px 20px;
    border: blue solid 1px;
    border-radius: 5px;
    text-align: center;
    margin-left: 10px;
    margin-top: 10px;
    
}
.centerAlignedScore {
    text-align: center;
}

.CompareRow {
    display: flex;
    justify-content: start;
}
.ScoreCompare{
    text-align: left;
}
.CompareHeaderColumn {
    min-width: 250px;
    min-height: 25px;
    font-weight: 500;
    position: sticky;
    left: 0;
    background-color: white;
    padding-left: 20px;
}
.IntersectionScore {
    position: sticky;
    left: 0;
    background-color: white;
    padding-left: 20px;
}
.CompareDataColumn {
    min-width: 250px;
    min-height: 25px;
    text-align: center;
}
.CompareDataColumnBlue {
    min-width: 250px;
    min-height: 25px;
    text-align: center;
    color: blue;
    font-weight: 600;
}
.CompareDataAddon {
    min-width: 50px;
    min-height: 30px;
    text-align: center;
    color: blue;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}


.DarkGreen {
    background-color: #01796F;
    border-color: #01796F;
    border-radius: 40px;
    width: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-weight: 800;
}
.Green {
    background-color: #ACE1AF;
    border-color: #ACE1AF;
    border-radius: 40px;
    width: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-weight: 800;
}
.yellow {
    background-color: #FDFD96;
    border-color: #FDFD96;
    border-radius: 40px;
    width: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    color: black;
    font-weight: 800;
}
.LightRed {
    background-color: #FA8072;
    border-color: #FA8072;
    border-radius: 40px;
    width: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-weight: 800;
}
.Red {
    background-color: #B7410E;
    border-color: #B7410E;
    border-radius: 40px;
    width: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-weight: 800;
}
.ScoreNav {
    width: 99%;
    padding: 5px 0px 5px 0px;
    display: flex;
    justify-content: left;
    font-size: 12px;
    background-color: white;
    border-bottom: 2px solid #F5F5F5;
}



