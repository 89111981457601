.CompanyNavMobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    font-size: 13px;
    font-weight: 500;
    position: fixed;
    background-color: white;
    z-index: 100;
    margin-top: 11px;
    overflow-x: auto;
}


.CompanyMenuMobile {
    cursor: pointer;
    padding: 5px;
    font-weight: 600;
}
