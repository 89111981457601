.Login {
    border: 1px solid #cccccc;
    color: black;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.Login:hover {
    background-color: hsl(245, 40%, 90%);
    transform: translateY(-3px);
}
