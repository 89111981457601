.FixedBanner {
    padding: 5px 0px 10px 0px;
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
}
.FixedBannerMobile {
    padding: 5px 0px 0px 0px;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
    background-color: white;
}
.textHeader {
    color: #000;
}
.HeaderAnchorMobile {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px;
    border-radius: 10px;
    background-color: #ddd;
}

.tool-menu {
    position: absolute;
    transform: translate(10px, 30px);
    min-width: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;
    max-height: 600px;
    background-color: #fff;
    z-index: 99;
}

.user-menu {
    position: absolute;
    transform: translate(-220px, 40px);
    min-width: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;
    max-height: 600px;
    background-color: #fff;
    z-index: 99;
}


.watchlist-menu {
    position: absolute;
    transform: translate(10px, 40px);
    min-width: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;
    max-height: 600px;
    background-color: #fff;
    z-index: 99;
    text-align:left;
}

.ToolOptionElement:hover {
    background-color: #B9D9EB;
}


.textHeaderAnchor {
    color: #000;
}
.dropdown-selected-value {
    
    font-weight: 500;
    color: #606f7b;
}
.textHeaderAnchor:hover {
    color: #000;
    font-weight: 500;
}
.textHeaderAnchor:active {
    font-weight: 500;
}
.HomeContentMobile{
    display: none;
}
