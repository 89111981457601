
#react-select-3-listbox{
    z-index: 10001;
    font-size: 14px;
}

#react-select-7-listbox {
    z-index: 10001;
    font-size: 14px;
}
.css-qc6sy-singleValue {
    color: #606f7b !important;
}
.Searchbox{
    border: 1px solid grey;
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
}

.css-1pahdxg-control {
    border: none !important;
    box-shadow: none !important;
}
.css-6j8wv5-Input{
    margin: .5px !important;
}