.ProfileContainer {
    display: flex;
    justify-content: space-between;
}
.leftOptionProfile {
    width: 23%;
    background-color: white;
    border-radius: 10px;
}
.RightDetailsProfile {
    width: 75%;
    border-left: 2px solid #F5F5F5;
    min-height: 500px;
    background-color: white;
    border-radius: 10px;
}
.ProfileNav {
    padding: 10px 15px;
    text-align:center;
    margin-bottom: 10px;
    border-radius: 5px;
}
.ProfileNav:hover {
    color: blue;
    cursor: pointer;
}