.CompanyMain {
    display: flex;
    justify-content: left;
    column-gap: 50px;
}
#CompanyMainLeft {
    padding-left: 40px;
    padding-top:20px;
}

a:hover{
    color:blue;
}

#CompanyMainCompanyName {
    font-size: 28px;
    font-weight: 600;
    text-align: left;
    padding-top: 10px;
}
#CompanyMainCompanyEodDetails {
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
}
#CompanyMainCompanyEodPrice {
    font-size: 20px;
    font-weight: 600;
}
#CompanyMainCompanyEodChange {
    font-size: 14px;
    padding-left: 5px;
    padding-top: 5px;
    font-weight: 600;
}
#CompanyMainCompanyEodDate {
    font-size: 12px;
    padding-left: 5px;
    padding-top: 10px;
    font-weight: 400;
    color:hsl(0, 0%, 50%) ;
}
#CompanyMainRight {
    display: flex;
    padding-right: 30px;
    margin-top: 25px;
}
.CompanyButton {
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border: 2px solid #4169e1;
    cursor: pointer;
    height: 30px;
}

.CompanyButton:hover {
        transform: translateY(-3px);
    }

#exchangeInfo a {
    text-decoration: none;
    padding-top: 10px;
    color: #606f7b;
}
#exchangeInfo a:hover {
    color: rgb(65, 105, 225);
}
#exchangeInfo {
    text-align: left;
    font-size: 12px;
    padding-bottom: 10px;
    padding-top: 5px;
    display: flex;
    justify-content: left;
    column-gap: 10px;
}
#compInfo {
    display: flex;
    justify-content: start;
}

.checkbox-wrapper-31:hover .check {
    stroke-dashoffset: 0;
}

.checkbox-wrapper-31 {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    padding-top: 8px;
}

.checkbox-wrapper-31 .background {
    fill: #ccc;
    transition: ease all 0.6s;
    -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 .stroke {
    fill: none;
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 2px;
    stroke-dashoffset: 100;
    stroke-dasharray: 100;
    transition: ease all 0.6s;
    -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 .check {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
    stroke-dashoffset: 0;
    stroke-dasharray: 22;
    transition: ease all 0.6s;
    -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 input[type=checkbox] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    opacity: 0;
    -appearance: none;
    -webkit-appearance: none;
}

    .checkbox-wrapper-31 input[type=checkbox]:hover {
        cursor: pointer;
    }

    .checkbox-wrapper-31 input[type=checkbox]:checked + svg .background {
        fill: #6cbe45;
    }

    .checkbox-wrapper-31 input[type=checkbox]:checked + svg .stroke {
        stroke-dashoffset: 0;
    }

    .checkbox-wrapper-31 input[type=checkbox]:checked + svg .check {
        stroke-dashoffset: 0;
    }